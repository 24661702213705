// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Layout from '../../Layout';
import MainMenu from './Menu';
import Service from '../../Service';
import BodyDetail from './BodyDetail';
import DocumentDetail from '../Documents/DocumentDetail';
import LoadingList from '../LoadingList';
import { Table } from 'react-bootstrap';
import {Box, MenuItem, Select, Snackbar} from '@mui/material'
import Error from '../Error';
import Loading from '../Loading';
import '../Assets/css/layout.css';
// import '../Assets/css/responsive.css';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterDropdown from "../Components/FilterDropdown/FilterDropdown";
import moment from "moment";
import { userInfoState } from '../../drive-feature/data-access/state';
import { shedOnScroll } from "../../data-access/app.state";
import { StateFragment, hookstate } from "@hookstate/core";
import GetStatusInterval from "../Components/GetStatusInterval/GetStatusInterval";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import downloadFileUrl from '../../Utils/download-file';
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";

export const searchOptionHistory = hookstate(null);
export const selectionOptionHistory = hookstate(null);
class Main extends Layout {

	constructor(props) {
		super(props);
		this.state.documents = null;
		this.state.selectedDoc = null;
		this.state.showDetail = false;
		this.state.currentDocInfo = null;
		this.state.docStatus = {};
		this.state.downloading = {};

		this.state.loadingDownload=  false;
		// this.state.users = null;
		this.state.objExpired = {};
		// this.state.function_type = 1;
		this.state.showHiddenMenu = false;
		this.state.searchOption = {
			// function_type: 1,
			active_flg: 1,
			status_id: '',
			sorting_code: 2,
			current: 1,
			page: 1,
			total: 0,
			page_size: 100,
			page_number: 1,
			create_user: '',
			create_user_sort: '',
			signature_method: '',
			signature_method_sort: '',
			document_type: '',
			document_type_sort: '',
			document_info: '',
			document_info_sort: '',
			document_status: '',
			document_status_sort: '',
			counter_party: '',
			counter_party_sort: '',
			amount: '',
			amount_sort: '',
			transaction_date: '',
			transaction_date_sort: '',
			action: '',
			action_sort: '',
			preservation_require: '',
			preservation_require_sort: '',
			function_type: '',
			function_type_list: '',
			function_type_list_sort: '',
			guest_visitation: '',
		}
		this.state.tabs = [
			{ "active_flg": 1, "label": "common:documents.document.active" },
			{ "active_flg": 0, "label": "common:documents.document.inactive" },]
		this.state.activeTab = 1;
		this.state.create_user_selection = [];
		this.state.signature_method_selection = [];
		this.state.document_type_selection = [];
		this.state.document_info_selection = [];
		this.state.document_status_selection = [];
		this.state.counter_party_selection = [];
		this.state.amount_selection = [];
		this.state.transaction_date_selection = [];
		this.state.action_selection = [];
		this.state.preservation_require_selection = [];
		this.state.function_type_list_selection = [];
		this.state.documents_statuses = [];
		this.state.selected_documents = [];
		this.state.batched_documents = [];
		this.state.init_batched_documents = [];
		this.state.isSelectedAll = false;

		this.state.documentSelectWithoutShift = null;
		this.state.secondPointer = null;
		this.state.firstPointer = null;

	}

	Menu = () => {
		return (
			<MainMenu {...this.props} page={this.state.page} />
		);
	}

	selectDocument = (ev, docInfo) => {
		// append selected document to selected_documents if it is not in the list, otherwise remove it from the list
		let { selected_documents, documents, documentSelectWithoutShift, secondPointer, firstPointer } = this.state;

		let newSelectedDocuments = [...selected_documents];


		if (ev.shiftKey) {
			// Handle Shift-click logic
			if(documentSelectWithoutShift == null){
				const index = documents.findIndex(item => item.id === docInfo.id);
				newSelectedDocuments = documents.slice(0, index+1)
				this.setState({
					documentSelectWithoutShift: docInfo
				})
			}else{
				const indexFirst = documents.findIndex(item => item.id === documentSelectWithoutShift.id);
				const indexSecond = documents.findIndex(item => item.id === docInfo.id);
				if(indexFirst < indexSecond){
					console.log(111111);
					console.log(firstPointer, indexFirst);
					if(firstPointer && indexFirst > firstPointer){
						for(let i = indexFirst; i >= firstPointer; i--){
							const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
							if(includesDoc !== -1){
								console.log(i);
								newSelectedDocuments.splice(includesDoc, 1);
							}
						}
					}
					if(secondPointer && secondPointer > indexSecond){
						for(let i = secondPointer; i >= indexSecond; i--){
							const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
							if(includesDoc !== -1){
								console.log(i);
								newSelectedDocuments.splice(includesDoc, 1);
							}
						}
					}

					this.setState({secondPointer: indexSecond});
					for(let i = indexFirst; i < indexSecond+1; i++){
						const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
						if(includesDoc === -1){
							newSelectedDocuments.push(documents[i])
						}
					}
				}else if(indexSecond < indexFirst){
					if(firstPointer && firstPointer < indexSecond){
						for(let i = indexSecond; i >= firstPointer; i--){
							const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
							if(includesDoc !== -1){
								console.log(i);
								newSelectedDocuments.splice(includesDoc, 1);
							}
						}
					}
					if(secondPointer && secondPointer > indexFirst){
						for(let i = secondPointer; i > indexFirst; i--){
							const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
							if(includesDoc !== -1){
								newSelectedDocuments.splice(includesDoc, 1);
							}
						}
					}
					this.setState({firstPointer: indexSecond});
					for(let i = indexSecond; i < indexFirst; i++){
						const includesDoc = newSelectedDocuments.findIndex(item => item.id === documents[i].id)
						if(includesDoc === -1){
							newSelectedDocuments.push(documents[i])
						}
					}
				}else{
					newSelectedDocuments.splice(indexSecond, 1);
				}
			}
		} else {
		// Handle regular click
			this.setState({
				documentSelectWithoutShift: docInfo,
				firstPointer: null,
				secondPointer: null
			})
			const index = newSelectedDocuments.findIndex(item => item.id === docInfo.id);
			if (index === -1) {
				newSelectedDocuments.push(docInfo);
			} else {
				newSelectedDocuments.splice(index, 1);
			}
		}
		const isSelectedAll = newSelectedDocuments.length === documents.length;
		this.setState({ selected_documents: newSelectedDocuments, isSelectedAll });
	}

	handleSelectAll = (checked) => {
		let { documents } = this.state;
		if (checked) {
			if (documents) {
				this.setState({ selected_documents: documents, isSelectedAll: true });
			}
		} else {
			this.setState({ selected_documents: [], isSelectedAll: false });
		}

	}

	BulkDocuments = () => {
		const  {t} = this.props;
		let { selected_documents, documents_statuses } = this.state;
		// filter items from selected documents that have action = please-sign
		const filtered_selected_documents = selected_documents.filter((document) => {
			return document.action === "please-sign";
		});

		if (filtered_selected_documents.length > 0) {
			// get list of document id from filtered_selected_documents
			const selected_documents_ids = filtered_selected_documents.map((document) => {
				return document.id;
			});

			Service.bulkSignDocuments(selected_documents_ids).then(resp => {
				// append filtered_selected_documents to documents_statuses and remove duplicated items
				let uniqueIds = new Set();
				const new_documents_statuses = [...documents_statuses, ...filtered_selected_documents].filter(document => {
					if (!uniqueIds.has(document.id)) {
						uniqueIds.add(document.id);
						return true;
					}
					return false;
				});

				const {documents} = this.state;

				const new_documents = documents;

				selected_documents_ids.forEach(id => {
					const documentIndex = new_documents.findIndex(doc => doc.id === id);
					if (documentIndex !== -1) {
						new_documents[documentIndex]['processing_flg'] = 1;
					}
				});

				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t("common:" + resp.message)}</div>
						);
					},
					action: []
				});

				this.setState({
					selected_documents: [],
					isSelectedAll: false,
					batched_documents: filtered_selected_documents,
					init_batched_documents: filtered_selected_documents,
					documents_statuses: new_documents_statuses,
					documents: new_documents,
				});
			}).catch(err => {
				console.log(err.response);
				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t("common:" + err.response.data.error)}</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
							this.BulkDocuments();
						}}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
			});

			this.UpdateModalDialog({
				body: Loading,
				action: [],
			});
		} else {
			this.modalToggle(false);
		}
	}

	BulkSignDocumentConfirmation = () => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:document.batch.signatures",
			body: () => {
				return (
					<div>{t('common:document.batch.signatures')}</div>
				);
			},
			action: [
				(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={() => {
							this.BulkDocuments();
						}}>
						<span>{t("common:general.ok")}</span>
					</Button>
				),
			],
			props: {
				"show": true,
				"centered": true,
			},
		});
	}

	showDetailPage = (ev, docInfo) => {
		searchOptionHistory.set({...this.state.searchOption});
		selectionOptionHistory.set({
			function_type_list_selection: this.state.function_type_list_selection,
			signature_method_selection: this.state.signature_method_selection,
			preservation_require_selection: this.state.preservation_require_selection,
			create_user_selection: this.state.create_user_selection,
			document_type_selection: this.state.document_type_selection,
			document_info_selection: this.state.document_info_selection,
			document_status_selection: this.state.document_status_selection,
			counter_party_selection: this.state.counter_party_selection,
			amount_selection: this.state.amount_selection,
			transaction_date_selection: this.state.transaction_date_selection,
			action_selection: this.state.action_selection,
			pathname: this.props.location.pathname
		})
		this.setState({ currentDocInfo: null, showHiddenMenu: false}, () => {
			const { history } = this.props;

			history.push(`/documents/detail/${docInfo.id}`, { from: this.state.page });
		});
	}

	getFileContent = () => {
		let { currentDocInfo, PDFFiles, SelectedFile } = this.state;
		let document_id = currentDocInfo.document_id;
		if (!PDFFiles[SelectedFile].file) {
			Service.getDocumentFile(document_id, SelectedFile).then(resp => {
				PDFFiles[SelectedFile].file = resp;
				this.setState({ PDFFiles });
			}).catch(err => {
				//console.log(err.response);
			});
		}
	}

	UpdateModalDialog = (options) => {
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		if (options.title) {
			modalTitle = options.title;
		}
		if (options.body) {
			ModalBody = options.body;
		}
		if (options.action) {
			ModalAction = options.action;
		}
		if (options.props) {
			modalProps = options.props;
		}
		this.setState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	DeleteDocumentSubmit = (document_id) => {
		let { t } = this.props;
		Service.DeleteDocument(document_id).then(resp => {
			// console.log(resp);
			let { documents } = this.state;
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:" + resp.message)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						this.modalToggle(false);
						for (let i in documents) {
							let doc = documents[i];
							if (doc.id === document_id) {
								documents.splice(i, 1);
								this.setState({ documents });
								break;
							}
						}
					}}>
						<span>{t("common:general.ok")}</span>
					</Button>),
				],
			});
		}).catch(err => {
			//console.log(err.response);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:" + err.response.data.error)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						this.DeleteDocumentSubmit(document_id);
					}}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
		this.UpdateModalDialog({
			body: Loading,
			action: [],
		});
	}

	DeleteDocumentConfirmation = (ev, document_id) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.delete.comfirm.header",
			body: () => {
				return (
					<div>{t("common:document.delete.comfirm.message")}</div>
				);
			},
			action: [
				(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={(ev) => {
							this.DeleteDocumentSubmit(document_id);
						}}>
						<span>{t("common:general.yes-delete")}</span>
					</Button>
				),
			],
			props: {
				"show": true,
				"centered": true,
			},
		});
	}

	StatusBarGroup = (props) => {
		let { t, location } = this.props;
		let { doc } = props;
		//let { function_type } = this.state;

		if (doc.status_id === 14 && doc.function_type_id === 4) {
			// doc.status_label = "document.status.cannot-timestamp";
			// doc.status_code = "error"
			doc.status_label = "document.status.protected-PDF-supported";
			doc.status_code = "confirmed";
		}
		if (doc.remarks === "protected" && [1, 2, 3].includes(doc.function_type_id)) {
			doc.status_code = "error";
		}
		let StatusItems = [(
			<div key="doc-status" className={"document-progress " + doc.status_code}>
				<span className="status-label">{t(`common:${doc.status_label}`)}</span>
			</div>
		)];
		if (doc['processing_flg'] === 1) {
			StatusItems = [(
				<div key="doc-status" className={"document-progress processing"}>
					<span className="status-label">{t(`common:document.status.processing`)}</span>
				</div>
			)];
		}

		if (location.pathname != '/verification' && doc.count_guests > 0) {
			if (doc.count_guests_downloaded > 0) {
				StatusItems.push((
    				<div key="doc-guest-status" className={"document-progress draft"}>
    					<span className="status-label">
    						{t(`common:document.status.guest-downloaded`)}
    						{doc.count_guests > 1 ? ` ${doc.count_guests_downloaded}/${doc.count_guests}`: ``}
    					</span>
    				</div>
    			))
			}
			else if (doc.count_guests_viewed > 0) {
				StatusItems.push((
    				<div key="doc-guest-status" className={"document-progress draft"}>
    					<span className="status-label">
    						{t(`common:document.status.guest-viewed`)}
    						{doc.count_guests > 1 ? ` ${doc.count_guests_viewed}/${doc.count_guests}`: ``}
    					</span>
    				</div>
    			))
			} else if (doc.guests_notified == 1 && doc.doc_status != 0) {
				StatusItems.push((
    				<div key="doc-guest-status" className={"document-progress draft"}>
    					<span className="status-label">
    						{t(`common:document.status.guest-unread`)}
    					</span>
    				</div>
    			))
			}

		}

		return StatusItems;
	}

	handleSetFilterOption = (filterOption) => {		
		let { searchOption } = this.state;
		const newFilter = {
			...searchOption,
			...filterOption
		};
		this.setState({
			searchOption: newFilter
		}, () => {
			this.GetDocumentListData(this.state.searchOption, false);
		});
	}

	handleSetFilterSelection = (column, value) => {
		this.setState({
			[`${column}_selection`]: value,
		});
	}

	handleClearAllFilter = () => {
		let { searchOption } = this.state;
		const newFilter = {
			...searchOption,
			create_user: '',
			create_user_sort: '',
			signature_method: '',
			signature_method_sort: '',
			document_type: '',
			document_type_sort: '',
			document_info: '',
			document_info_sort: '',
			counter_party: '',
			counter_party_sort: '',
			amount: '',
			amount_sort: '',
			transaction_date: '',
			transaction_date_sort: '',
			action: '',
			action_sort: '',
			preservation_require: '',
			preservation_require_sort: '',
			function_type_list: '',
			function_type_list_sort: '',
		};
		this.setState({
			searchOption: newFilter,
			create_user_selection: [],
			signature_method_selection: [],
			document_type_selection: [],
			document_info_selection: [],
			document_status_selection: [],
			counter_party_selection: [],
			amount_selection: [],
			transaction_date_selection: [],
			action_selection: [],
			preservation_require_selection: [],
			function_type_list_selection: [],
			selected_documents: [],
			isSelectedAll: false,
		}, () => {
			this.GetDocumentListData(this.state.searchOption);
		});
	}

	handleIsFiltered = () => {
		let {
			create_user,
			create_user_sort,
			signature_method,
			signature_method_sort,
			document_type,
			document_type_sort,
			document_info,
			document_info_sort,
			counter_party,
			counter_party_sort,
			amount,
			amount_sort,
			transaction_date,
			transaction_date_sort,
			action,
			action_sort,
			preservation_require,
			preservation_require_sort,
			function_type_list,
			function_type_list_sort,
		} = this.state.searchOption;

		let check = {
			create_user,
			create_user_sort,
			signature_method,
			signature_method_sort,
			document_type,
			document_type_sort,
			document_info,
			document_info_sort,
			counter_party,
			counter_party_sort,
			amount,
			amount_sort,
			transaction_date,
			transaction_date_sort,
			action,
			action_sort,
			preservation_require,
			preservation_require_sort,
			function_type_list,
			function_type_list_sort,
		};

		let result = true;

		Object.keys(check).map(key => {
			if (check[key].length > 0) {
				result = false;
			}
		});
		return result;
	}

	OptionalColumnAfterCreatorHead = () => {
		let { t } = this.props;
		let { function_type } = this.state;

		if (function_type === 1) {
			return (
				<th key={27} className="th-sign-level" id="signature_method">
					<FilterDropdown
						column={"signature_method"}
						onFilter={this.handleSetFilterOption}
						sort_type={this.state.searchOption.signature_method_sort}
						filter_selection={this.state.searchOption.signature_method}
						function_type={function_type}
						t={t}
						label={t("common:documents.document-info.sign-level")}
						search_option={this.state.searchOption}
						filter_detail_selection={this.state.signature_method_selection}
						onFilterDetail={this.handleSetFilterSelection}
					/>
				</th>
			);
		}

		if (function_type === 4) {
			return [(
				<th key={28} className="th-function-type" id="preservation_require">
					<FilterDropdown
						t={t}
						onFilter={this.handleSetFilterOption}
						column={"function_type_list"}
						function_type={function_type}
						sort_type={this.state.searchOption.function_type_list_sort}
						filter_selection={this.state.searchOption.function_type_list}
						label={t("common:documents.document-info.function-type")}
						search_option={this.state.searchOption}
						filter_detail_selection={this.state.function_type_list_selection}
						onFilterDetail={this.handleSetFilterSelection}
					/>
				</th>
			),
			(
				<th key={29} className="th-preservation-require" id="preservation_require">
					<FilterDropdown
						t={t}
						onFilter={this.handleSetFilterOption}
						column={"preservation_require"}
						sort_type={this.state.searchOption.preservation_require_sort}
						filter_selection={this.state.searchOption.preservation_require}
						function_type={function_type}
						label={t("common:documents.document-info.preservation-require")}
						search_option={this.state.searchOption}
						filter_detail_selection={this.state.preservation_require_selection}
						onFilterDetail={this.handleSetFilterSelection}
					/>
				</th>
			)];
		}

		return null;
	}

	CounterPartyHead = () => {
		let { t } = this.props;
		let { function_type } = this.state;

		return (
			<th key={29} className="th-counter-party" id="counter_party">
				<FilterDropdown
					t={t}
					onFilter={this.handleSetFilterOption}
					function_type={function_type}
					column={"counter_party"}
					sort_type={this.state.searchOption.counter_party_sort}
					filter_selection={this.state.searchOption.counter_party}
					label={t("common:documents.document-info.counter-party")}
					search_option={this.state.searchOption}
					filter_detail_selection={this.state.counter_party_selection}
					onFilterDetail={this.handleSetFilterSelection}
				/>
			</th>
		);
	};

	ItemsList = () => {
		let { t } = this.props;
		let { documents, function_type } = this.state;
		let { StatusBarGroup, OptionalColumnAfterCreatorHead, CounterPartyHead } = this;

		let HeadList = (
			<StateFragment state={shedOnScroll}>
				{s => (
					<tr key="listheader" className={`${s.get({ noproxy: true }) ? "shed" : ""}`}>
						<th key={19} className="th-selection-box"></th>
						<th key={20} className="th-stamp-box"></th>
						<th key={21} className="th-creator" id={"create_user"}>
							<FilterDropdown
								function_type={function_type}
								sort_type={this.state.searchOption.create_user_sort}
								filter_selection={this.state.searchOption.create_user}
								column={"create_user"}
								t={t}
								onFilter={this.handleSetFilterOption}
								label={t("common:documents.creator")}
								search_option={this.state.searchOption}
								filter_detail_selection={this.state.create_user_selection}
								onFilterDetail={this.handleSetFilterSelection}
							/>
						</th>

						<OptionalColumnAfterCreatorHead />

						{
							function_type !== 4 &&
							<th key={22} className="th-doctype" id="document_type">
								<FilterDropdown
									onFilter={this.handleSetFilterOption}
									function_type={function_type}
									column={"document_type"}
									sort_type={this.state.searchOption.document_type_sort}
									filter_selection={this.state.searchOption.document_type}
									t={t}
									label={t('common:documents.document-info.document-type')}
									search_option={this.state.searchOption}
									filter_detail_selection={this.state.document_type_selection}
									onFilterDetail={this.handleSetFilterSelection}
								/>
							</th>
						}
						<th key={23} className="th-imprints" id="document_status">
							<FilterDropdown
								t={t}
								onFilter={this.handleSetFilterOption}
								column={"document_status"}
								function_type={function_type}
								sort_type={this.state.searchOption.document_status_sort}
								filter_selection={this.state.searchOption.document_status}
								label={t('common:documents.document-info.imprints')}
								search_option={this.state.searchOption}
								filter_detail_selection={this.state.document_status_selection}
								onFilterDetail={this.handleSetFilterSelection}
								guest_visitation_selection={this.state.searchOption.guest_visitation}
							/>
						</th>
						<th key={24} className="th-action" id="action">
							<FilterDropdown
								t={t}
								onFilter={this.handleSetFilterOption}
								function_type={function_type}
								column={"action"}
								sort_type={this.state.searchOption.action_sort}
								filter_selection={this.state.searchOption.action}
								label={t('common:documents.document-info.action')}
								search_option={this.state.searchOption}
								filter_detail_selection={this.state.action_selection}
								onFilterDetail={this.handleSetFilterSelection}
							/>
						</th>

						<CounterPartyHead />

						<th key={25} className="th-amount-info" id="amount">
							<FilterDropdown
								t={t}
								onFilter={this.handleSetFilterOption}
								function_type={function_type}
								column={"amount"}
								sort_type={this.state.searchOption.amount_sort}
								filter_selection={this.state.searchOption.amount}
								label={t("common:documents.document-info.amount")}
								search_option={this.state.searchOption}
								filter_detail_selection={this.state.amount_selection}
								onFilterDetail={this.handleSetFilterSelection}
							/>
						</th>
						<th key={26} className="th-document-created-date" id="transaction_date">
							<FilterDropdown
								t={t}
								function_type={function_type}
								onFilter={this.handleSetFilterOption}
								column={"transaction_date"}
								sort_type={this.state.searchOption.transaction_date_sort}
								filter_selection={this.state.searchOption.transaction_date}
								label={t("common:documents.issued-date")}
								search_option={this.state.searchOption}
								filter_detail_selection={this.state.transaction_date_selection}
								onFilterDetail={this.handleSetFilterSelection}
							/>
						</th>
						<th key={27} className="stamp-delete">
						</th>
					</tr>
				)}
			</StateFragment>
		);

		if (documents === null) {
			return <LoadingList />;
		}

		if (documents.length === 0) {
			return (
				<Table ref={ref => this.tableRef = ref}>
					<tbody>
						<tr className="document-row-list error-tr">
							<thead key={0} >
								{HeadList}
							</thead>
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let ItemsList = [];
		for (let doc of documents) {
			let btnDetailClass = ["sign-status"];

			let OptionalColumnAfterCreator = () => {
				if (function_type === 1) {
					return (
						<td
							key={7}
							className="sign-level-info clickable"
							onClick={(ev) => {
								this.showDetailPage(ev, doc);
							}}
						>
							<div className="inner-container">
								<span>{t(`common:documents.document-info.sign-level-${doc.sign_level}`)}</span>
							</div>
						</td>
					);
				} else if (function_type === 4) {

					let item_preservation_require = '';

					if (parseInt(doc.preservation_require) === 1) {
						item_preservation_require = 'common:documents.document-info.other';
					} else if (parseInt(doc.preservation_require) === 2) {
						item_preservation_require = 'common:documents.document-info.new-article-seven';
					} else if (parseInt(doc.preservation_require) === 3) {
						item_preservation_require = 'common:documents.document-info.scanner-storage';
					}

					return [(
						<td
							key={8}
							className="function-type-info clickable"
							onClick={(ev) => {
								this.showDetailPage(ev, doc);
							}}
						>
							<div className="inner-container">
								<span>{t(`common:${doc.function_type}`)}<br />{doc.doc_type_name}</span>
								{/* <div>{doc.doc_type_name}</div> */}
							</div>
						</td>
					), (
						<td
							key={9}
							className="function-type-info clickable"
							onClick={(ev) => {
								this.showDetailPage(ev, doc);
							}}
						>
							<div className="inner-container">
								<span>{t(item_preservation_require)}</span>
							</div>
						</td>
					)];
				} else {
					return null;
				}
			}

			let DeleteAction = () => {
				if (doc.doc_status === 5 || (doc.doc_status === 0 && doc.create_user === userInfoState.get({ noproxy: true })?.user_id)) {
					return (
						<Tooltip title={t("common:tooltip.common.delete-draft")} arrow>
							<IconButton
								onClick={(ev) => {
									this.DeleteDocumentConfirmation(ev, doc.id);
								}}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					);
				} else {
					return null;
				}
			}

			// let DownloadAction = () => {
			// 	if ([2, 3].includes(doc.doc_status)) {
			// 		let { downloading } = this.state;
			// 		// console.log(downloading);
			// 		if (Object.keys(downloading).includes(doc.id.toString())) {
			// 			return (
			// 				<td className="document-downloading doc-row-action">
			// 					<FontAwesomeIcon icon={faSpinner} pulse className="action-button" />
			// 				</td>
			// 			);
			// 		} else {
			// 			return (
			// 				<td className="document-download doc-row-action">
			// 					<FontAwesomeIcon icon={faDownload} className="action-button" onClick={(ev) => {
			// 						let { downloading } = this.state;
			// 						downloading[doc.id] = null;
			// 						this.setState({ downloading }, () => {
			// 							this.onDownloadRequestHandler(ev, doc.id);
			// 						});
			// 					}} />
			// 				</td>
			// 			);
			// 		}
			// 	} else {
			// 		return null;
			// 	}
			// }

			let CounterParty = () => {
				if (doc.counter_party_info || doc.counter_party_name) {
					return (
						<td
							key={9}
							className="counter-party-info clickable"
							onClick={(ev) => {
								this.showDetailPage(ev, doc);
							}}
						>
							<div className="inner-container">
								<span>{doc?.counter_party_info ?? ""}</span>
							</div>
						</td>
					);
				} else {
					return (
						<td
							key={10}
							className="counter-party-info clickable"
						>
							<div className="inner-container">
							</div>
						</td>
					);
				}
			}


			let SignInfo = () => {
				if (function_type === 4) {
					if (doc.action === "please-confirm") {
						return (
							<div key="doc-sign">
								<Chip label={t(`common:documents.action.please-confirm`)}
									variant="outlined"
									sx={{
										color: "error.dark",
										borderColor: "error.dark",
										borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
									}}
									size="small"
								/>
							</div>
						);
					} else if (doc.action === "confirmed") {
						return (
							<div key="doc-sign">
								<Chip label={t(`common:documents.action.confirmed`)}
									variant="outlined"
									sx={{
										color: "success.dark",
										borderColor: "success.dark",
										borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
									}}
									size="small"
								/>
							</div>
						);
					}
				}

				if (doc.action === "please-sign") {
					return (
						<div key="doc-sign">
							<Chip label={t(`common:documents.action.please-sign`)}
								variant="outlined"
								sx={{
									color: "error.dark",
									borderColor: "error.dark",
									borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
								}}
								size="small"
							/>
						</div>
					);
				} else if (doc.action === "signed") {
					return (
						<div key="doc-sign">
							<Chip label={t(`common:documents.action.signed`)}
								variant="outlined"
								sx={{
									color: "success.dark",
									borderColor: "success.dark",
									borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
								}}
								size="small"
							/>
						</div>
					);
				}
				return null;
			}

			let ButtonSign = () => {
				// if (doc.sign_permission !== 1) return null;

				if (function_type === 4) {
					if (["please-confirm"].includes(doc.action)) {
						btnDetailClass.push("wait-sign");
						return <CircleNotificationsIcon color="error" />;
					} else if (["confirmed"].includes(doc.action)) {
						btnDetailClass.push("signed");
						return <CheckCircleIcon color="success" />;
					}
				}

				if (function_type !== 4 && doc.signed === 1) {
					btnDetailClass.push("signed");
					if (["signed", "send-sender"].includes(doc.status_code)) {
						//
					} else if (function_type === 4 && parseInt(doc.status_id) === 14) {
						//
					} else {
						return <CheckCircleIcon color="disabled" />;
					}
				}

				if (doc.action === "please-sign") {
					btnDetailClass.push("wait-sign");
					return <CircleNotificationsIcon color="error" />;
				} else if (doc.action === "signed") {
					btnDetailClass.push("signed");
					return <CheckCircleIcon color="success" />;
				}
			}

			let Amount = parseFloat(doc.amount || "0").toString();
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			let convertAmount = Amount[0];
			if (parseInt(Amount[1]) > 0) {
				convertAmount = Amount.join(".")
			}
			Amount = convertAmount + " " + (doc.currency || "JPY");

			let linkageClass = "";
			if (doc.master_document_id) {
				linkageClass = " has-linkage";
			}

			let isSelected = this.state.selected_documents.findIndex(item => item.id === doc.id) !== -1;


			ItemsList.push(
				<tr
					key={doc.id}
					className={`document-row-list${linkageClass} ${isSelected ? 'selected' : ''} ${doc.collab_user == 1 ? 'row-highlighted' : ''}`}
				>
					{userInfoState.get({ noproxy: true })?.archive_mode !== 1 && (
						<td key={0} className={"selection-box clickable"}
							 onClick={(ev) => {
								 this.selectDocument(ev, doc);
							 }}
						>
							<IconButton
								className="btn-custom"
								color="primary"
							>
								{isSelected ? <CheckBoxOutlinedIcon/> : <CheckBoxOutlineBlankIcon/>}
							</IconButton>
						</td>
					)}

					<td key={1} className={"stamp-box clickable " + btnDetailClass.join(" ")}
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<ButtonSign />
					</td>
					<td key={2} className="creator-info clickable"
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<div className="inner-container">
							<span>{doc.full_name}</span>
						</div>
					</td>
					<OptionalColumnAfterCreator />
					{function_type !== 4 && (
						<td
							key={3}
							className="title-info clickable"
							onClick={(ev) => {
								this.showDetailPage(ev, doc);
							}}
						>
							<div className="inner-container">
								<span>{doc.doc_type_name}</span>
							</div>
						</td>
					)}
					<td
						key={4}
						className="title-info clickable"
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							<div className={"status-container"}>
								<StatusBarGroup doc={doc} />
							</div>
						</div>
					</td>
					<td
						key={5}
						className="sign-info clickable"
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<div className="inner-container">
							<div className="unsigned-container">
								<SignInfo />
							</div>
						</div>
					</td>

					<CounterParty />

					<td
						key={6}
						className="amount-info clickable"
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<div className="inner-container">
							<span>{Amount}</span>
						</div>
					</td>
					<td
						key={7}
						className="document-created-date clickable"
						onClick={(ev) => {
							this.showDetailPage(ev, doc);
						}}
					>
						<div className="inner-container">
							<span>{doc.received_date && moment(doc.received_date).format("YYYY-MM-DD")}</span>
						</div>
					</td>
					{/* <DownloadAction /> */}
					<td
						key={8}
						className="document-delete doc-row-action stamp-delete"
					>
						<DeleteAction />
					</td>
				</tr>
			);
		}

		const [showSwipeIcon, setShowSwipeIcon] = useState(true);

    // handleScroll 関数をコンポーネント内で定義
    const handleScroll = () => {
      if (window.scrollX === 0) {
        setShowSwipeIcon(false);
      }
    };

    useEffect(() => {
      // コンポーネントがマウントされたときにイベントリスナーを追加
      window.addEventListener('scroll', handleScroll);

	  document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);

	  if (this.isFirstMount) {
		const row = this.tableRef.getElementsByTagName('tr')?.[0];
		const cols = row?.children;

		if (cols) {
			const userCache = localStorage.getItem("user-info");
			const userInfo = userCache && userCache !== "undefined" && userCache !== "null"
				? JSON.parse(userCache)
				: null;
			const userId = userInfo?.user_id ?? '';

			this.storageKey = `colWidths_${userId}_${this.state.function_type}`;
			const storedWidths = JSON.parse(localStorage.getItem(this.storageKey)) ?? {};

			console.log("==> storedWidths", storedWidths);

			// fixed width of selection-box(30), stamp-box(40), stamp-delete(55)
			let tableWidth = 125;

			const colWidths = {};
			for (let i = 0; i < cols.length; i++) {
				const cell = cols[i];
				if (cell.id) {
					let width = storedWidths[cell.id] ?? cell.offsetWidth;
					cell.style.width = width + 'px';
					tableWidth += width;
					colWidths[cell.id] = width;
				}
			}

			console.log("==> useEffect", colWidths, tableWidth);
			this.tableRef.style.width = tableWidth + 'px';
			this.colWidths = colWidths;
		}
	  }
      // コンポーネントがアンマウントされるときにイベントリスナーを削除
      return () => {
        window.removeEventListener('scroll', handleScroll);
		document.removeEventListener('mousemove', this.handleMouseMove);
    	document.removeEventListener('mouseup', this.handleMouseUp);
		this.isFirstMount = false;
      };
    }, []);

    return (
      <div className="body-wrapper-scroll fixed" onScroll={handleScroll}>
        {/* {showSwipeIcon && (
          <div className="swipe-icon">
            <span className="material-symbols-outlined">swipe_right</span>
            <span>右にスワイプできます</span>
          </div>
        )} */}

		{/* fixed width of selection-box(30), stamp-box(40), stamp-delete(55) */}
        <ResizableTableWithCache storageKey={`colWidths_${this.state.function_type}`} widthOffset={125}>
          <thead key={0} >
            {HeadList}
          </thead>
          <tbody key={1} >
            {ItemsList}
          </tbody>
        </ResizableTableWithCache>
      </div>
    );
	}

	// HeadList

	GetAllUserList = () => {
		if(!!searchOptionHistory.get() && this.props?.location.pathname === selectionOptionHistory.get({noproxy: true})?.pathname){
			selectionOptionHistory.set({
				pathname: ''
			})
			this.setState({
				...this.state,
				...selectionOptionHistory.get({noproxy: true})
			})
			this.setState({
				searchInput: searchOptionHistory.get().search
			})
			this.GetDocumentListData({...searchOptionHistory.get()}, false);
			let { searchOption } = this.state;
			const newFilter = {
				...searchOption,
				create_user: '',
				create_user_sort: '',
				signature_method: '',
				signature_method_sort: '',
				document_type: '',
				document_type_sort: '',
				document_info: '',
				document_info_sort: '',
				counter_party: '',
				counter_party_sort: '',
				amount: '',
				amount_sort: '',
				transaction_date: '',
				transaction_date_sort: '',
				action: '',
				action_sort: '',
				preservation_require: '',
				preservation_require_sort: '',
				function_type_list: '',
				function_type_list_sort: '',
				search: ''
			};
			this.CountSignDoc({...newFilter});
		}else{
			this.handleClearAllFilter()
		}
	}

	CountSignDoc = (searchOption) => {
		Service.getDocumentList(searchOption).then(resp => {
			let documents = resp.payload;
			const userInfoGet = userInfoState.get({noproxy:true});
			let docFilterSigned = resp.payload.filter((a) => a.action === "please-confirm" || a.action === "please-sign").length;
			switch (searchOption.function_type) {
				case 1:
					if (docFilterSigned !== userInfoGet?.signed_document_signer && userInfoGet?.signed_document_signer <= documents.length){
						userInfoState.set(v=>{
							localStorage.setItem('user-info', JSON.stringify({...v, signed_document_signer: docFilterSigned}));
							return ({
								...v, signed_document_signer: docFilterSigned})
						});
					}
					break;
				case 2:
					if (docFilterSigned !== userInfoGet?.signed_document_sender && userInfoGet?.signed_document_sender <= documents.length){
						userInfoState.set(v=>{
							localStorage.setItem('user-info', JSON.stringify({...v, signed_document_sender: docFilterSigned}));

							return ({
							...v, signed_document_sender: docFilterSigned})});

					}
					break;
				case 3:
					if (docFilterSigned !== userInfoGet?.signed_document_circle && userInfoGet?.signed_document_circle <= documents.length){
						userInfoState.set(v=>{
							localStorage.setItem('user-info', JSON.stringify({...v, signed_document_circle: docFilterSigned}));

							return ({
							...v, signed_document_circle: docFilterSigned})});

					}
					break;
				case 4:
					if (docFilterSigned !== userInfoGet?.signed_document_verification && userInfoGet?.signed_document_verification <= documents.length){
						userInfoState.set(v=>{

							localStorage.setItem('user-info', JSON.stringify({...v, signed_document_verification: docFilterSigned}));

							return({ ...v, signed_document_verification: docFilterSigned})});

						// localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
					}
					break;
				default:
					break;
			}
		}).catch(err => {
			console.log(err.response);
		});
	}

	componentDidMountExtends = async () => {
		let { function_type, docStatus, code } = this.state;
		Service.GetPageData(code);
		// for fix bug status list on evaluation screen (search dialog)
		if ([4, 7].includes(function_type)) {
			Service.getDocumentStatusList({ function_type }).then(resp => {
				// let { docStatus } = this.state;
				docStatus = {};
				for (let rec of resp) {
					docStatus[rec.id] = rec;
				}
				this.setState({ docStatus }, () => {
					if (this.componentDidMountOptional) {
						if (typeof this.componentDidMountOptional === "function") {
							this.componentDidMountOptional(this.GetAllUserList);
						}
					} else {
						this.GetAllUserList();
					}
				});
			}).catch(err => {
				this.setState({ docStatus }, () => {
					if (this.componentDidMountOptional) {
						if (typeof this.componentDidMountOptional === "function") {
							this.componentDidMountOptional(this.GetAllUserList);
						}
					} else {
						this.GetAllUserList();
					}
				});
				console.log(err.response);
			})
		} else {
			this.setState({ docStatus }, () => {
				if (this.componentDidMountOptional) {
					if (typeof this.componentDidMountOptional === "function") {
						this.componentDidMountOptional(this.GetAllUserList);
					}
				} else {
					this.GetAllUserList();
				}
			});
		}
		// console.log(docStatus);

	}

	handleGetDocumentStatusForList() {
		let { documents, documents_statuses, batched_documents } = this.state;

		if (documents?.length > 0 && documents_statuses?.length > 0) {
			Service.getDocumentStatusForList({
				document_list: JSON.stringify(documents_statuses.map(item => item.document_id)),
			})
				.then((resp) => {
					if (resp.ok) {
						const newBatchedDocuments = [...batched_documents];
						const payload = resp['payload'];

						const documentMapIds = new Map(documents.map((document, index) => [document.document_id, index]));

						Object.entries(payload).forEach(([document_id, payloadDocument]) => {
							const index = documentMapIds.get(parseInt(document_id));
							const updatedDocument = {
								...documents[index],
								...payloadDocument,
							};

							documents[index] = updatedDocument;

							if (updatedDocument.action !== "please-sign" && updatedDocument.action !== "please-confirm") {
								const index = newBatchedDocuments.findIndex(item => item.id === parseInt(document_id));
								if (index !== -1) {
									newBatchedDocuments.splice(index, 1);
								}
							}
						});

						const newDocumentsStatuses = documents.filter(item => item['processing_flg'] === 1 ||
							(item.action !== "signed" && item.action !== "confirmed" && newBatchedDocuments.some(prev_item => prev_item.id === item.id))
						);

						this.setState({
							documents,
							documents_statuses: newDocumentsStatuses,
							batched_documents: newBatchedDocuments,
						});
					}
				})
				.catch(() => {
					//
				})
		}
	}

	PageCalculation = (searchOption) => {
		searchOption.current = searchOption.page_number;
		searchOption.page = Math.ceil(searchOption.total / searchOption.page_size);
		return searchOption;
	}

	GetDocumentListData = (searchOption, isGetAllDoc) => {		
		searchOption = this.PageCalculation(searchOption);
		this.setState({ documents: null, searchOption, selected_documents: [], isSelectedAll: false, activeTab: searchOption.active_flg });
		// console.log(searchOption);
		Service.getDocumentList(searchOption).then(resp => {
			let { documents, documents_statuses } = this.state;
			documents = resp.payload;
			searchOption.total = resp.total;
			searchOption = this.PageCalculation(searchOption);
			documents_statuses = resp.payload.filter(item => item.processing_flg === 1);
			// console.log(documents);
			this.setState({ documents, searchOption, documents_statuses });
			// console.log(documents.map(a=> ({signed: a.signed, status: a.status_code, status_id: a.status_id})),
			// documents.filter(a=> a.signed === 0 && a.status_code !== "draft" && a.sign_permission === 1 && a.status_id !== 14 && a.status_id !== 5 && a.status_id !== 0))
			if (isGetAllDoc) {
				const userInfoGet = userInfoState.get({noproxy:true});
				let docFilterSigned = resp.payload.filter((a) => a.action === "please-confirm" || a.action === "please-sign").length;
				switch (searchOption.function_type) {
					case 1:
						if (docFilterSigned !== userInfoGet?.signed_document_signer && userInfoGet?.signed_document_signer <= documents.length){
							userInfoState.set(v=>{
								localStorage.setItem('user-info', JSON.stringify({...v, signed_document_signer: docFilterSigned}));
								return ({
									...v, signed_document_signer: docFilterSigned})
							});
						}
						break;
					case 2:
						if (docFilterSigned !== userInfoGet?.signed_document_sender && userInfoGet?.signed_document_sender <= documents.length){
							userInfoState.set(v=>{
								localStorage.setItem('user-info', JSON.stringify({...v, signed_document_sender: docFilterSigned}));

								return ({
								...v, signed_document_sender: docFilterSigned})});

						}
						break;
					case 3:
						if (docFilterSigned !== userInfoGet?.signed_document_circle && userInfoGet?.signed_document_circle <= documents.length){
							userInfoState.set(v=>{
								localStorage.setItem('user-info', JSON.stringify({...v, signed_document_circle: docFilterSigned}));

								return ({
								...v, signed_document_circle: docFilterSigned})});

						}
						break;
					case 4:
						if (docFilterSigned !== userInfoGet?.signed_document_verification && userInfoGet?.signed_document_verification <= documents.length){
							userInfoState.set(v=>{

								localStorage.setItem('user-info', JSON.stringify({...v, signed_document_verification: docFilterSigned}));

								return({ ...v, signed_document_verification: docFilterSigned})});

							// localStorage.setItem('user-info', JSON.stringify(userInfoState.get({noproxy: true})));
						}
						break;
					default:
						break;
				}
			}
			searchOptionHistory.set(null)
		}).catch(err => {
			console.log(err.response);
		});
	}

	handleSearchDocumentMultiField = (text) => {
		const newSearchOption = {
			...this.state.searchOption,
			current: 1,
			page: 1,
			page_number: 1,
		};
		if (text.length === 0) {
			delete newSearchOption.search;
			this.setState({
				searchOption: newSearchOption
			}, () => this.GetDocumentListData(this.state.searchOption));
		} else {
			this.setState({
				searchOption: newSearchOption
			}, () => this.GetDocumentListData({ ...this.state.searchOption, search: text }));
		}
	}

	onDownloadRequestHandler = (ev, document_id) => {
		Service.downloadAllAsZip(document_id).then(resp => {
			// console.log(resp);
			// let filename = new Date();
			if (resp.ok) {
				downloadFileUrl(resp.payload.url, 'zip').finally(()=>{
					let { downloading } = this.state;
					// console.log(downloading);
					delete downloading[document_id];
					this.setState({ downloading });
				});

			}
		}).catch(err => {
			console.log(err.response);
		});
	}

	onListDownloadRequestHandler = () => {
		let { searchOption } = this.state;
		this.setState({loadingDownload: true});
		Service.getDocumentListDownload(searchOption).then(resp => {
			// let aLink = document.createElement("a");
			// aLink.href = resp.url;
			// aLink.click();
			downloadFileUrl(resp.url, 'csv').finally(()=>{

				this.setState({loadingDownload: false});
				/* */
				// modalProps = { "show": false };
				// /* */
				// this.setState({ modalProps });
			});
		}).catch(err => {

			this.setState({loadingDownload: false})
			console.log(err.response);
		});
	}

	onPageChangeHandler = (ev) => {
		let page = parseInt(ev.target.value);
		let { searchOption } = this.state;
		let nextPage = page;
		if (nextPage !== searchOption.page_number) {
			searchOption.page_number = nextPage;
			this.GetDocumentListData(searchOption);
		}
	}

	onRecordLimitChangeHandler = (ev) => {
		let limit = parseInt(ev.target.value);
		let { searchOption } = this.state;
		if (limit !== searchOption.page_size) {
			let cursor = (searchOption.current - 1) * searchOption.page_size;
			searchOption.page_number = Math.floor(cursor / limit) === 0 ? 1 : Math.floor(cursor / limit);
			searchOption.page_size = limit;
			this.GetDocumentListData(searchOption);
		}
	}

	onSortingHandler = (ev) => {
		let sort = parseInt(ev.target.value);
		let { searchOption } = this.state;
		if (sort !== searchOption.sorting_code) {
			searchOption.sorting_code = sort;
			this.GetDocumentListData(searchOption);
		}
	}

	onFunctionTypeChangeHandler = (ev) => {
		// console.log(selected);
		let selected = parseInt(ev.target.value) || null;

		let { searchOption } = this.state;
		if (selected !== searchOption.function_type) {
			searchOption.function_type = selected;
			this.GetDocumentListData(searchOption);
		}
	}

	onPreservationRequireChangeHandler = (ev) => {
		// console.log(selected);
		let selected = parseInt(ev.target.value) || null;

		let { searchOption } = this.state;
		if (selected !== searchOption.preservation_require) {
			searchOption.preservation_require = selected;
			this.GetDocumentListData(searchOption);
		}
	}

	onStatusChangeHandler = (ev) => {
		let status = ev.target.value;
		if (status !== "all") {
			status = parseInt(ev.target.value);
		} else {
			status = null;
		}
		let { searchOption } = this.state;
		if (status !== searchOption.status_id) {
			searchOption.status_id = status;
			this.GetDocumentListData(searchOption);
		}
	}

	prevPageData = (ev) => {
		let { searchOption, PossiblePrevPage } = this.state;
		let nextPage = searchOption.page_number - 1;
		if (nextPage >= 0) {
			searchOption.page_number = nextPage;
			this.GetDocumentListData(searchOption);
			PossiblePrevPage = true;
		} else {
			PossiblePrevPage = false;
		}
		this.setState({ PossiblePrevPage });
	}

	nextPageData = (ev) => {
		let { searchOption, PossibleNextPage } = this.state;
		let nextPage = searchOption.page_number + 1;
		if (nextPage <= searchOption.page) {
			searchOption.page_number = nextPage;
			this.GetDocumentListData(searchOption);
			PossibleNextPage = true;
		} else {
			PossibleNextPage = false;
		}
		this.setState({ PossibleNextPage });
	}

	ClearSeletedDoc = (docInfo) => {
		this.setState({ showDetail: false });
		let { documents } = this.state;
		for (let doc of documents) {
			if (doc.id === docInfo.id) {
				let status = docInfo.status_list[docInfo.doc_status];
				doc.signed = docInfo.signed;
				doc.doc_status = status.id;
				doc.status_code = status.code;
				doc.status_label = status.label;
				break;
			}
		}
		// console.log(documents);
		this.setState({ documents, currentDocInfo: null, showHiddenMenu: false });
	}

	DocumentDetailBody = (props) => {
		// let { t } = this.props;
		let { currentDocInfo } = this.state;
		let { ReturnButton } = this;

		if (!currentDocInfo) {
			return <Loading />;
		}
		if (currentDocInfo === false) {
			return <Error />;
		}
		// console.log(currentDocInfo);
		return (
			<DocumentDetail docInfo={currentDocInfo} action={ReturnButton} />
		);
	}

	close = (ev, docInfo) => {
		ev.target.closest(".document-detail").classList.remove("active");
		this.ClearSeletedDoc(docInfo);
	}

	ReturnButton = (props) => {
		let { t } = this.props;
		let { docInfo } = props;
		// console.log(props);
		return (
			// <button className="btn-return" onClick={(ev) => {
			// 	this.close(ev, docInfo);
			// }}>
			//	<FontAwesomeIcon icon={faShareSquare} className="fa-flip-horizontal" title={t("common:document.return-to-list-page")} />
			//</button>
			<div>
				<Tooltip title={t("common:document.return-to-list-page")} arrow>
					<IconButton
						className='btn-action'
						onClick={(ev) => {
							this.close(ev, docInfo);
						}}>
						<ArrowBackOutlinedIcon fontSize='inherit' />
					</IconButton>
				</Tooltip>
			</div>
		);
	}

	DocumentDetailPage = (props) => {
		let { showDetail } = this.state;
		let { DocumentDetailBody } = this;
		return (
			<BodyDetail active={showDetail}>
				<DocumentDetailBody />
			</BodyDetail>
		);

	}

	PageListNavigator = () => {
		let { searchOption } = this.state;
		let navItems = [];
		let currentFrom = 0;
		let pageTo = 0;
		let pageFrom = 0;
		let currentTo = 0;
		let total = searchOption.total;
		let page_number = searchOption.page_number;
		let page_size = searchOption.page_size;
		let current = searchOption.current;
		if (!searchOption.total) { return <CircularProgress size="1rem" /> }
		for (let i = 0; i < searchOption.page; i++) {
			let page = i + 1;
			if (total === 0) {
				pageFrom = 0;
			} else {
				pageFrom = ((page - 1) * page_size) + 1;
			}
			if (total < page * page_size) {
				pageTo = total
			} else {
				pageTo = page * page_size;

			}
			navItems.push(
				<MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
			);
		}
		if (total === 0) {
			currentFrom = 0;
		} else {
			currentFrom = ((current - 1) * page_size) + 1;
		}
		if (total < current * page_size) {
			currentTo = total
		} else {
			currentTo = current * page_size;
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={current}
					onChange={this.onPageChangeHandler}
					renderValue={() => `${currentFrom} - ${currentTo} / ${searchOption.total}`}
					variant={"standard"}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: 250,
								minWidth: 130
							},
						},
					}}
					style={{ color: "#007BFF" }}>
					{navItems}
				</Select>
			</Box>
		);
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { code } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Button
				key="document-create"
				sx={{ ml: 1, borderRadius: 19 }}
				variant="contained"
				startIcon={<AddIcon />}
				className="as-link"
				component={Link}
				to={`/${code}/documents/create`}
			>
				{t('common:main.general.create')}
			</Button>
		);

		return ButtonList;
	}

	AdditionalButtons = () => {
		let { t } = this.props;
		let { searchOption, documents, loadingDownload } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<IconButton
				sx={{ ml: 2, mr: 2, mt: 1 }}
				key="list-reload"
				color="primary"
				style={{
					marginTop: "8px", marginLeft: "16px", padding: "3px"
				}}
				disabled={documents ? false : true}
				onClick={(ev) => {
					this.GetDocumentListData(searchOption);
				}}
			>
				{documents ? <RefreshIcon /> : <CircularProgress size="1em" />}
			</IconButton>
		);

		ButtonList.push(
			<Tooltip key={0} title={t('common:tooltip.common.download-csv')} arrow>
				<Button
					sx={{ mr: 2, mt: 1 }}
					key="list-download"
					variant="outlined"
					size="small"
					startIcon={loadingDownload ? <CircularProgress size={16}/> : <DownloadIcon />}
					onClick={loadingDownload ? undefined : this.onListDownloadRequestHandler}
				>
					{/* {"CSVダウンロード"} */}
					{t('common:main.general.list-download')}
				</Button>
			</Tooltip>
		)


		return ButtonList;
	}

	HeaderRightNav = () => {
		let { t } = this.props;
		let { searchOption } = this.state;
		const { PageListNavigator } = this;
		let checkFiltered = this.handleIsFiltered()

		return (
			<div className="header-right">
				{!checkFiltered && (
					<span
						className={"clear-all-filter"}
						onClick={this.handleClearAllFilter}
					>
						{t("common:documents.filter.clear-all")}
					</span>
				)}

				<Box py={1} px={2}>
					<Select
						value={searchOption.page_size}
						onChange={this.onRecordLimitChangeHandler}
						renderValue={() => searchOption.page_size + " " + t("common:search.view.record-per-page")}
						variant={"standard"}
						style={{ color: "#007BFF" }}
					>
						{[100, 200, 500, 1000].map((count, index) =>
							<MenuItem value={count} key={index} >{count + " " + t("common:search.view.record-per-page")}</MenuItem>
						)}
					</Select>
				</Box>

				<div className="pagination">

					<PageListNavigator />

					<IconButton
						onClick={this.prevPageData}
						disabled={searchOption.current === 1 ? true : false} >
						<ChevronLeftIcon />
					</IconButton>

					<IconButton
						onClick={this.nextPageData}
						disabled={searchOption.current === searchOption.page ? true : false}>
						<ChevronRightIcon />
					</IconButton>
				</div>

			</div>
		);
	}

	FilterTabs = () => {

		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			let currentActiveFlg = this.state.tabs[i].active_flg
			/* */
			let c = "select-tab-item";

			/* */
			if (currentActiveFlg === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { pagination, datalist, searchOption } = this.state;

				/* */
				let newFilter = { ...searchOption };
				newFilter.active_flg = currentActiveFlg;
				this.setState({
					activeTab: currentActiveFlg,
					searchOption: newFilter
				}, () => {
					this.GetDocumentListData(this.state.searchOption, false);
				});

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}


	render() {
		let { t } = this.props;
		let { code, showDetail } = this.state;
		const { Main, ItemsList, DocumentDetailPage, HeaderRightNav, OperationButtons, AdditionalButtons } = this;		

		const isMobileResponsive = showDetail;
		return (
			<>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					open={this.state.batched_documents.length > 0}
					message={
						<div className="upload_progess_message">
							<CircularProgress
								variant="determinate"
								value={(this.state.init_batched_documents.length - this.state.batched_documents.length)/this.state.init_batched_documents.length * 100}
							/>
							<div>
								{t("common:documents.bulk-signed")}: {this.state.init_batched_documents.length - this.state.batched_documents.length}/{this.state.init_batched_documents.length}
							</div>
						</div>
					}
				/>

				<GetStatusInterval
					onGetStatus={() => {
						this.handleGetDocumentStatusForList();
					}}
					timeout={5000}
				/>
				<div className={isMobileResponsive ? "d-sm-block" : ""}>
					<Main>
						<DocumentDetailPage />
						<div className={`${code}-container`}>
							<div className="header-wrapper above">
								<div className="header-left">
                  <AdditionalButtons />
								</div>
								<div className="header-right">

									{code === "evaluation" ? null : <div className="lz-flex-0">
										<div className="lz-m-10 lz-mb-0">
											<this.FilterTabs />
										</div>

									</div>}
								</div>
							</div>
							<div className="header-wrapper bottom">
								<div className="header-left">
									<OperationButtons />
								</div>
								<HeaderRightNav />
							</div>

							<div className="body-wrapper body-wrapper-scroll">
								<ItemsList />
							</div>
						</div>
					</Main>
				</div>
				{isMobileResponsive && (
					// hide menu when showing detailInfo
					<div className="main contrainer-wrapper d-block d-sm-none">
						<div className="layout">
							<DocumentDetailPage />
						</div>
					</div>
				)}
			</>
		);

	}

}

export default Main;
