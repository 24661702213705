import React, {useCallback, useEffect, useState} from "react";
import {Divider, Fade, InputAdornment, ListSubheader, MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import '../../Assets/css/layout.css';
import './FilterDropdown.css';
import {ArrowDropDown, ArrowDropUp, Close, SortByAlpha} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Service from "../../../Service";
import CircularProgress from "@mui/material/CircularProgress";
import {DocumentFilterEnum} from "./constants";
import moment from "moment/moment";
import Button from "@mui/material/Button";


const idColumnProcess = [
    DocumentFilterEnum.create_user,
    DocumentFilterEnum.document_type,
    DocumentFilterEnum.function_type_list,
    DocumentFilterEnum.document_info,
    DocumentFilterEnum.signature_method,
    DocumentFilterEnum.document_status,
];

const nameColumnProcess = [
    DocumentFilterEnum.action,
    DocumentFilterEnum.amount,
    DocumentFilterEnum.counter_party,
    DocumentFilterEnum.transaction_date,
    DocumentFilterEnum.preservation_require,
];

export default function FilterDropdown(
    props,
) {
    const {
        label,
        function_type,
        column,
        t,
        sort_type,
        filter_selection,
        onFilter,
        search_option,
        filter_detail_selection,
        onFilterDetail,
        guest_visitation_selection
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [filterList, setFilterList] = useState([]);
    const [filterSelection, setFilterSelection] = useState([]);
    const [filterDetailSelection, setFilterDetailSelection] = useState([]);
    const [initFilterSelection, setInitFilterSelection] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [enableLoadMore, setEnableLoadMore] = useState(true);
    const [searchOption, setSearchOption] = useState({});
    const [sortType, setSortType] = useState('');

    const [guestVisitationList, setGuestVisitationList] = useState([]);
    const [selectedGuestVisitationFilters, setSelectedGuestVisitationFilters] = useState([]);
    const [initGuestVisitationFilters, setInitGuestVisitationFilters] = useState([]);

    const handleMoveSelectedItemToFirst = (initArray) => {
        if (filterSelection.length === 0) {
            return initArray;
        }
        let uniqueArray = [...filterDetailSelection, ...initArray].filter((obj, index, self) => {
            return index === self.findIndex((t) => {
                if (idColumnProcess.includes(column)) {
                    return t.id === obj.id;
                } else if (nameColumnProcess.includes(column)) {
                    return t.name === obj.name;
                }
            });
        });

        let remainingItems = uniqueArray.filter((obj) => {
            if (idColumnProcess.includes(column)) {
                if (obj.id === null) {
                    return !filterSelection.includes("null")
                }
                return !filterSelection.includes(obj.id)
            } else if (nameColumnProcess.includes(column)) {
                if (obj.name === null) {
                    return !filterSelection.includes("null")
                }
                return !filterSelection.includes(obj.name)
            }
        });

        return [...filterDetailSelection, ...remainingItems];
    }

    const handleGetFilterList = useCallback((searchFilterOption = {}) => {
        if(['counter_party', 'amount', 'transaction_date'].includes(column)){
            return;
        }
        if (!isLoading && enableLoadMore) {
            setIsLoading(true);

            Service.getDocumentFilterList({
                ...searchOption,
                ...searchFilterOption,
                [column]: "",
            }).then(resp => {
                const {payload, ok, guest_visitation} = resp;    

                let new_list = [];

                let newSearchOption = {
                    ...searchOption,
                    ...searchFilterOption,
                };

                if (ok) {
                    if (payload.length > 0) {
                        if (newSearchOption.page_number === 1) {
                            new_list = [...payload];
                        } else {
                            new_list = [...filterList, ...payload];
                        }
                        setFilterList(handleMoveSelectedItemToFirst(new_list));
                        setGuestVisitationList(guest_visitation);
                        setSearchOption(newSearchOption);
                    } else if (payload.length === 0) {
                        setEnableLoadMore(false)
                    }
                }
            }).catch((error) => {

            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [searchOption, handleMoveSelectedItemToFirst])

    const open = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
        setFilterList([]);
        setGuestVisitationList([]);
        setEnableLoadMore(true);
        handleGetFilterList({
            ...searchOption,
            page_number: 1,
        });
        setAnchorEl(event.currentTarget);
    }, [handleGetFilterList, searchOption]);

    const handleClose = useCallback(() => {
        setFilterList([]);
        setGuestVisitationList([]);
        setSearchOption({
            ...searchOption,
            page_number: 1,
        });
        setAnchorEl(null);
    }, [searchOption]);

    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 16;

    const handleSearch =  useCallback((event) => {
        const input = event.target.value;
        if(['counter_party', 'amount', 'transaction_date'].includes(column)){
            setSearchOption({
                ...searchOption,
                [column]: input,
            });
        } else {
            setSearchOption({
                ...searchOption,
                page_number: 1,
                search_filter: input,
            });
            setEnableLoadMore(true);
        }
    }, [searchOption]);

    const handleSearchKeyEnter = useCallback((event) => {
        const { keyCode, target } = event;

        const { value: searchString } = target;
        
        if (keyCode !== "Escape") {
            event.stopPropagation();
        }

        if (keyCode === 13) {

            if(['counter_party', 'amount', 'transaction_date'].includes(column)){
                setSearchOption({
                    ...searchOption,
                    [column]: searchString,
                    page_number: 1,
                    search_filter: searchString,
                });
                onFilter({
                    [`${column}_sort`]: sortType,
                    [`${column}`]: searchString,
                });
                return
            }
            
            setSearchOption({
                ...searchOption,
                page_number: 1,
                search_filter: searchString,
            });
            setEnableLoadMore(true);
            setFilterList([]);
            setGuestVisitationList([]);
            handleGetFilterList({
                ...searchOption,
                page_number: 1,
                search_filter: searchString,
            })
        }
    }, [searchOption, handleGetFilterList])

    const handleClearSearchInput = useCallback(() => {
        setSearchOption({
            ...searchOption,
            page_number: 1,
            search_filter: "",
        });
        setEnableLoadMore(true);
        setFilterList([]);
        setGuestVisitationList([]);
        handleGetFilterList({
            ...searchOption,
            page_number: 1,
            search_filter: "",
        });
    }, [searchOption, handleGetFilterList])

    const handleScroll = (event) => {
        const target = event.target;
        if (target) {
            if ((target.scrollHeight - target.scrollTop) === target.clientHeight) {
                if (anchorEl !== null) {
                    handleGetFilterList({
                        ...searchOption,
                        page_number: searchOption.page_number + 1,
                    });
                }
            }
        }
    };

    const convertLabel = (option) => {
        switch (column) {
            case DocumentFilterEnum.action:
                if (option.name) {
                    return t(`common:documents.action.${option.name}`)
                }
                return t("common:documents.filter.empty")
            case DocumentFilterEnum.amount:
                if (option.name) {
                    let Amount = parseFloat(option.name || "0").toString();
                    Amount = Amount.split(".");
                    Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    let convertAmount = Amount[0];
                    if (parseInt(Amount[1]) > 0) {
                        convertAmount = Amount.join(".")
                    }
                    Amount = convertAmount + " " + (option.currency || "JPY");
                    return Amount;
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.transaction_date:
                if (option.name) {
                    return moment(option.name).format("YYYY-MM-DD");
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.document_type:
                if (option.code) {
                    return t(`common:documents.document-info.options-${option.code}`);
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.create_user:
            case DocumentFilterEnum.counter_party:
                if (option.name) {
                    return option.name
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.document_info:
                if (option.id && option.name) {
                    return `${option.id} - ${option.name}`
                } else if (option.id) {
                    return `${option.id}`
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.document_status:
                return t(`common:${option.label}`)
            case DocumentFilterEnum.function_type_list:
                if (option.label) {
                    return t(`common:${option.label}`);
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.preservation_require:
                if (option.name) {
                    let output = '';
                    if (parseInt(option.name) === 1) {
                        output = 'common:documents.document-info.other';
                    } else if (parseInt(option.name) === 2) {
                        output = 'common:documents.document-info.new-article-seven';
                    } else if (parseInt(option.name) === 3) {
                        output = 'common:documents.document-info.scanner-storage';
                    }
                    return t(output);
                }
                return t("common:documents.filter.empty");
            case DocumentFilterEnum.signature_method:
                if (option.name) {
                    return t(`common:documents.document-info.sign-level-${option.name}`);
                }
                return t("common:documents.filter.empty");
            default:
                if (option.name) {
                    return option.name
                }
                return t("common:documents.filter.empty");
        }
    };

    const convertLabelGuestVisitation = (option) => {
        switch (option.name) {
            case "guest_unread":
                return t("common:document.status.guest-unread")
            case "guest_viewed":
                return t("common:document.status.guest-viewed")
            case "guest_downloaded":
                return t("common:document.status.guest-downloaded")
            default:
                return t("common:documents.filter.empty");
        }
    };

    const handleFilter = useCallback(() => {
        switch (column) {
            case DocumentFilterEnum.counter_party:
            case DocumentFilterEnum.amount:
            case DocumentFilterEnum.transaction_date:
                // let convert = filterSelection.map((item) => {
                //     if (item === null) {
                //         return "null";
                //     }
                //     return item
                // })
                onFilter({
                    [`${column}_sort`]: sortType,
                    [`${column}`]: searchOption[column] || '',
                });
                break;
            default:
                onFilter({
                    [`${column}_sort`]: sortType,
                    [`${column}`]: filterSelection.join(","),
                    'guest_visitation': selectedGuestVisitationFilters.join(","),
                });
                break;
        }
    }, [filterSelection, sortType, column, filter_selection, selectedGuestVisitationFilters])

    const handleClearFilter = useCallback(() => {
        if ((sort_type.length === 0 && sort_type !== sortType) 
            || (filter_selection.length === 0 && JSON.stringify(filterSelection) !== JSON.stringify(initFilterSelection)) 
            || (guest_visitation_selection.length === 0 && JSON.stringify(selectedGuestVisitationFilters) !== JSON.stringify(initGuestVisitationFilters))
        ) {
            setFilterSelection([]);
            setSelectedGuestVisitationFilters([]);
            setSortType("");
            return;
        }
        onFilter(
            {
                [`${column}_sort`]: "",
                [`${column}`]: "",
            },
        );
        onFilterDetail(column, []);
        setFilterSelection([]);
        setSelectedGuestVisitationFilters([]);
    }, [column, sort_type, sortType, filter_selection, filterSelection, onFilterDetail, guest_visitation_selection, selectedGuestVisitationFilters])

    const handleConfirmFilter = useCallback(() => {
        setAnchorEl(null);
        handleFilter();
        onFilterDetail(column, filterDetailSelection);
        setFilterList([]);
        setGuestVisitationList([]);
        setSearchOption({
            ...searchOption,
            page_number: 1,
        });
    }, [handleFilter, onFilterDetail, column, filterDetailSelection]);

    const isSelected = (option) => {
        if (filterSelection.length > 0) {
            if (idColumnProcess.includes(column)) {
                let {id} = option
                if (id === null) {
                    id = "null";
                }
                return filterSelection.includes(id);
            } else if (nameColumnProcess.includes(column)) {
                let {name} = option;
                if (name === null) {
                    name = "null";
                }
                return filterSelection.includes(name);
            } else {
                return false;
            }
        }
        return false;
    };

    const isSelectedGuestVisitation = (option) => {
        if (selectedGuestVisitationFilters.length > 0) {
            if (column === DocumentFilterEnum.document_status) {
                let {id} = option
                if (id === null) {
                    id = "null";
                }
                return selectedGuestVisitationFilters.includes(id);
            } else {
                return false;
            }
        }
        return false;
    };

    const handleSelectItem = (option) => {
        if (idColumnProcess.includes(column)) {
            let {id} = option
            if (id === null) {
                id = "null";
            }
            if (filterSelection.includes(id)) {
                setFilterSelection(filterSelection.filter(item => item !== id));
                setFilterDetailSelection(filterDetailSelection.filter(item => item.id !== id));
            } else {
                setFilterSelection([...filterSelection, id]);
                setFilterDetailSelection([...filterDetailSelection, {...option, id: id === null ? "null" : id}]);
            }
        } else if (nameColumnProcess.includes(column)) {
            let {name} = option
            if (name === null) {
                name = "null";
            }
            if (filterSelection.includes(name)) {
                setFilterSelection(filterSelection.filter(item => item !== name));
                setFilterDetailSelection(filterDetailSelection.filter(item => item.name !== (name === "null" ? null : name)));
            } else {
                setFilterSelection([...filterSelection, name]);
                setFilterDetailSelection([...filterDetailSelection, option]);
            }
        }
    };

    const handleSelectGuestVisitationItem = (option) => {
        if (idColumnProcess.includes(column)) {
            let {id} = option
            if (id === null) {
                id = "null";
            }

            if (selectedGuestVisitationFilters.includes(id)) {
                setSelectedGuestVisitationFilters(selectedGuestVisitationFilters.filter(item => item !== id));
                // setFilterDetailSelection(filterDetailSelection.filter(item => item.id !== id));
            } else {
                setSelectedGuestVisitationFilters([...selectedGuestVisitationFilters, id]);
                // setFilterDetailSelection([...filterDetailSelection, {...option, id: id === null ? "null" : id}]);
            }
        }
    };

    const searchInputComponent = () => {
        const enableSearchColumns = [
            DocumentFilterEnum.document_info,
            DocumentFilterEnum.create_user,
            DocumentFilterEnum.counter_party,
            DocumentFilterEnum.amount,
            DocumentFilterEnum.transaction_date
        ];
        if(['counter_party', 'amount', 'transaction_date'].includes(column)){
            return (
                <div
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                >
                    <TextField
                        size="small"
                        autoFocus
                        placeholder={t("common:documents.search.document-search")}
                        fullWidth
                        style={{
                            borderRadius: 100,
                        }}
                        variant="outlined"
                        sx={{
                            "& fieldset": {
                                border: 'none',
                            },
                        }}
                        className={"filter-search"}
                        InputProps={{
                            style: {
                                fontSize: 14,
                            },
                            endAdornment: (
                                searchOption?.search_filter?.length > 0 && (
                                    <InputAdornment
                                        className="header-search-close"
                                        position="end"
                                        onClick={handleClearSearchInput}
                                    >
                                        <Close />
                                    </InputAdornment>
                                )
                            )
                        }}
                        value={searchOption[column]}
                        onChange={handleSearch}
                        onKeyDown={handleSearchKeyEnter}
                    />
                </div>
            );
        }
        if (enableSearchColumns.includes(column)) {
            return (
                <div
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                        marginTop: 8,
                    }}
                >
                    <TextField
                        size="small"
                        autoFocus
                        placeholder={t("common:documents.search.document-search")}
                        fullWidth
                        style={{
                            borderRadius: 100,
                        }}
                        variant="outlined"
                        sx={{
                            "& fieldset": {
                                border: 'none',
                            },
                        }}
                        className={"filter-search"}
                        InputProps={{
                            style: {
                                fontSize: 14,
                            },
                            endAdornment: (
                                searchOption?.search_filter?.length > 0 && (
                                    <InputAdornment
                                        className="header-search-close"
                                        position="end"
                                        onClick={handleClearSearchInput}
                                    >
                                        <Close />
                                    </InputAdornment>
                                )
                            )
                        }}
                        value={searchOption?.search_filter}
                        onChange={handleSearch}
                        onKeyDown={handleSearchKeyEnter}
                    />
                </div>
            );
        }
    }

    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) &&
            !isNaN(parseFloat(str))
    }

    const handleConvertArray = () => {
        let array = "";
        if (filter_selection && filter_selection.length > 0) {
            switch (column) {
                case DocumentFilterEnum.counter_party:
                    // let convertNull = JSON.parse(filter_selection).map((item) => {
                    //     return item;
                    // })
                    // setFilterSelection(convertNull);
                    // setInitFilterSelection(convertNull);
                    break;
                default:
                    array = filter_selection.split(",");
                    const filterColumns = [DocumentFilterEnum.amount, DocumentFilterEnum.transaction_date];
                    let convert = array.map((item) => {
                        if (
                            isNumeric(item)
                            && !filterColumns.includes(column)
                        ) {
                            return parseInt(item);
                        } else if (typeof item === 'string') {
                            return item;
                        }
                        return item;
                    })
                    setFilterSelection(convert);
                    setInitFilterSelection(convert);
                    break;
            }
        }

        if (guest_visitation_selection && guest_visitation_selection.length > 0) {
            let array = guest_visitation_selection.split(",");
            let convert = array.map((item) => {
                if (isNumeric(item)) {
                    return parseInt(item);
                } else if (typeof item === 'string') {
                    return item;
                }
            })
            setSelectedGuestVisitationFilters(convert);
            setInitGuestVisitationFilters(convert);
        }
    }

    useEffect(() => {
        if (search_option) {
            setSearchOption({
                function_type: function_type !== 4 ? function_type : "",
                column: column,
                status_id: "",
                status_id_list: search_option.status_id_list ?? "",
                function_type_list: search_option.function_type_list ?? "",
                create_user: search_option.create_user ?? "",
                signature_method: search_option.signature_method ?? "",
                document_type: search_option.document_type ?? "",
                document_info: search_option.document_info ?? "",
                document_status: search_option.document_status ?? "",
                counter_party: search_option.counter_party ?? "",
                amount: search_option.amount ?? "",
                transaction_date: search_option.transaction_date ?? "",
                action: search_option.action ?? "",
                preservation_require: search_option.preservation_require ?? "",
                page_size: 30,
                page_number: 1,
                search: search_option.search ?? "",
                search_filter: "",
                active_flg: search_option.active_flg ?? 1,
                guest_visitation: search_option.guest_visitation ?? "",
            })
            handleConvertArray();
        }
        if (sort_type) {
            setSortType(sort_type);
        }
        if (filter_detail_selection) {
            setFilterDetailSelection(filter_detail_selection);
        }
    }, [search_option, column, sort_type, filter_detail_selection]);

    return (
        <>
            <div
                className="header-inner-container"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                onClick={handleClick}
            >
                <span
                    className={`${filter_selection.length > 0 && 'is-filtered'}`}
                >
                    {label}
                </span>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <ArrowDropUp
                        style={{
                            marginBottom: -16,
                            color: sort_type === "asc" ? "#007BFF" : "#C7C7C7FF"
                        }}
                    />
                    <ArrowDropDown
                        style={{
                            color: sort_type === "desc" ? "#007BFF" : "#C7C7C7FF"
                        }}
                    />
                </div>
            </div>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
                        width: 320,
                    },
                    onScroll: handleScroll
                }}
            >
                <ListSubheader
                    className={"list-subheader"}
                    style={{
                        padding: 0
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginLeft: 8,
                            marginRight: 8,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <SortByAlpha
                                fontSize={"large"}
                                style={{
                                    fontSize: 24,
                                    color: "#cccccc"
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginLeft: 8,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                    }}
                                >
                                    <span
                                        className={`sort-label ${sortType === 'asc' && 'active'}`}
                                        onClick={() => {
                                            if (sortType === 'asc') {
                                                setSortType("");
                                            } else {
                                                setSortType("asc");
                                            }
                                        }}
                                    >
                                        {t("common:documents.filter.ascending")}
                                    </span>
                                    <span> / </span>
                                    <span
                                        className={`sort-label ${sortType === 'desc' && 'active'}`}
                                        onClick={() => {
                                            if (sortType === 'desc') {
                                                setSortType("");
                                            } else {
                                                setSortType("desc");
                                            }
                                        }}
                                    >
                                        {t("common:documents.filter.descending")}
                                    </span>
                                </Typography>
                                {(sort_type?.length > 0 || filter_selection?.length > 0 || filterSelection?.length > 0 || sortType?.length > 0 || guestVisitationList?.length > 0) && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {(sortType !== sort_type 
                                            || JSON.stringify(filterSelection) !== JSON.stringify(initFilterSelection) 
                                            || JSON.stringify(selectedGuestVisitationFilters) !== JSON.stringify(initGuestVisitationFilters)
                                        ) && (
                                            <Button
                                                onClick={handleConfirmFilter}
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    padding: 0,
                                                    fontSize: 12,
                                                    marginRight: 8,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                OK
                                            </Button>
                                        )}
                                        <Typography
                                            style={{
                                                fontSize: 13
                                            }}
                                            className={"clear-button"}
                                            onClick={handleClearFilter}
                                        >
                                            Clear
                                        </Typography>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    {/* <Divider sx={{my: 1.5, p:0}} /> */}

                    {searchInputComponent()}
                </ListSubheader>
                {filterList && filterList?.length > 0 && !['counter_party', 'amount', 'transaction_date'].includes(column) ? filterList?.map((option, i) => (
                    <MenuItem
                        key={i}
                        value={option}
                        style={{
                            padding: 0
                        }}
                        onClick={() => {
                            handleSelectItem(option)
                        }}
                    >
                        <Checkbox
                            style={{
                                color: "#007BFF"
                            }}
                            checked={isSelected(option)}
                            onClick={() => {
                                handleSelectItem(option)
                            }}
                        />
                        {convertLabel(option)}
                    </MenuItem>
                )) : (
                    <div>
                    </div>
                )}

                {guestVisitationList && guestVisitationList?.length > 0 && (
                    <Divider />
                )}

                {guestVisitationList && guestVisitationList?.length > 0 && guestVisitationList?.map((option, i) => (
                    <MenuItem
                        key={i}
                        value={option}
                        style={{
                            padding: 0
                        }}
                        onClick={() => {
                            handleSelectGuestVisitationItem(option)
                        }}
                    >
                        <Checkbox
                            style={{
                                color: "#007BFF"
                            }}
                            checked={isSelectedGuestVisitation(option)}
                            onClick={() => {
                                handleSelectGuestVisitationItem(option)
                            }}
                        />
                        {convertLabelGuestVisitation(option)}
                    </MenuItem>
                ))}
                {isLoading && (
                    <div className={"loading-container"}>
                        <CircularProgress color={"inherit"} size={20} />
                    </div>
                )}
            </Menu>
        </>

    );

}

FilterDropdown.defaultProps = {

};
